<template>
	<div>
		<article>
			<section class="notice_article js-parallax scrolled">
				<div class="notice_article_head">
					<h1 class="notice_article_head_ttl typesquare_option">标题</h1>
					<span class="notice_article_head_time">2020.10/13</span>
				</div>
				<div class="notice_article_body">
					<div class="notice_article_body_text ">
						详情内容
					</div>
				</div>
			</section>
			<div class="notice_pagenation">
				<a class="notice_pagenation_a typesquare_option" href="#"><span class=" typesquare_option">返回顶部</span></a>
			</div>
		</article>
		<div class="notice_banners">
			<a class="notice_banners_banner2 md-banner3" href="../read.html">
				<p class="notice_banners_banner2_ttl md-banner3_ttl typesquare_option">
					<span class="notice_banners_banner2_ttl_span md-banner3_ttl_span typesquare_option">清水寺の読み物</span>
				</p>
				<p class="notice_banners_banner2_txt md-banner3_txt">清水寺を育んだ人々の営みと観音信仰。<br>歴史のなかで紡がれたさまざまな物語をお届けします。</p>
			</a>
		</div>
	</div>
</template>

<script>
	export default {

		data() {
			return {
				data: '测试内容'
			}
		}
	}
</script>

<style>

</style>
